<template>
	<main>
		<HeaderTab :title="$t('global.effectif_title')" />

		<div id="content">
			<div class="container-fluid">
				<div v-for="category in horse_categories" :key="category.name" class="row effectif">
					<div class="col-12">
						<h2 class="justify-content-center mb-2">{{ category.name }}</h2>
						<div class="box">
                            <div v-for="horse in grouped_horses[category.name]" :key="horse.horse_id" class="effectif_horse">
                                <span>
                                    <h3><router-link :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">{{ horse.horse_nom | multiCapitalize }}</router-link></h3>
                                    <!-- S'il y a un ou plusieurs croisements, c'est qu'il s'agit d'une poulinière -->
                                    <template v-if="horse.season_mare.length > 0">
                                        {{ horse.horse_birth_year }}, ({{ horse.pedigree.pere | multiCapitalize }} x {{ horse.pedigree.mere | multiCapitalize }})
                                        <br>
                                        <!-- <span v-html="constructMareRow(horse)"></span> -->
                                    </template>
                                    
                                    <template v-else>
                                        {{ horse.sexe_code }}. {{ horse.robe_code | lowercase }}, {{ displayBirthData(horse) }}, ({{ horse.pedigree.pere | multiCapitalize }} x {{ horse.pedigree.mere | multiCapitalize }})
                                    </template>
                                </span>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
import _groupBy from 'lodash/groupBy'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'
import Horse from '@/mixins/Horse.js'

export default {
	name: "Effectif",
	mixins: [Horse],
	data () {
		return {
			grouped_horses: [],
            horse_categories: [],
			table_busy: true ,
            actual_year: null,
			config_table_hrefs: {
				'horse_nom': {
					routeName: 'HorseFicheInfo',
					params: {
						horse_id: 'horse_id'
					}
				}
			}
		}
	},
	created () {
		this.init_component()
	},
	methods: {
		async init_component() {
            this.actual_year = (new Date()).getFullYear()

			this.getHorseEffectif().then(res => {
                // Chevaux
				this.grouped_horses   = _groupBy(res, 'horse_categorie')

                // Catégories
                const temp = res.map(h => { 
                    return { 
                        'name' : h.horse_categorie, 
                        'order': h.horse_categorie_order 
                    }
                })
                this.horse_categories = _sortBy(
                    _uniqBy(temp, 'name')
                , 'order')

				this.table_busy = false
			})
		},
        displayBirthData(horse) {
            if(horse.horse_birth_year == this.actual_year
                || horse.horse_birth_year == (this.actual_year - 1)
            ) {
                return horse.horse_datenaissance
            }
            return horse.horse_birth_year
        },
        constructMareRow(horse) {
            const temp = horse.season_mare.map(sm => `${sm.season.season_label}: ${sm.seasonmarestallion[0].horse.horse_nom}`)
            return this.$options.filters.multiCapitalize(temp.join('<br>'))
        }
	},
	components: {
		HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
	}
}
</script>
